import React from 'react'

export const AboutMock = () => {
  return (
    <div className="about-container">
      <p>
        <strong>1977 – Fundação dos Supermercados Cidade Canção</strong>
      </p>
      <p>
        Esta história começa com a família Cardoso que iniciou sua trajetória
        quando fundou a primeira loja dos Supermercados Cidade Canção na vila
        Morangueira na cidade de Maringá, uma pequena loja de 200m², que
        proporcionou ao Sr. Manuel Marques Cardoso o sustento da família,
        trabalhavam lá também todos os filhos do Sr. Manuel inclusive Sr. Carlos
        Tavares Cardoso (Presidente executivo na CSD), Sr. Paulo Cardoso
        (IMembro do Conselho Administrativo) e Ângela Maria Cardoso Camilo
        (Membro do Conselho Administrativo), a empresa fundou 12 lojas em 30
        anos de existência.
      </p>
      <p>
        <strong>1982 – Fundação dos Supermercados São Francisco</strong>
      </p>
      <p>
        A primeira loja dos Supermercados São Francisco localizava - se no
        bairro das Palmeiras na cidade de Maringá.A família Nogaroli que sempre
        teve espírito empreendedor e foi impulsionada pelo sonho do imigrante
        italiano Sr.Valdir Nogarolli de vencer no novo país. A empresa fundou 16
        novas lojas em dois estados até o final desta década.Sempre trabalharam
        na empresa os filhos do Sr.Valdir, Jefferson Nogarolli(Presidente do
        Conselho Administrativo), Valdir Nogaroli(Diretor Comercial), Jeane
        Nogarolli Guiotti(Membro do Conselho Administrativo) e Francisco
        Nogarolli.
      </p>
      <p>
        <strong> 2010 – CSD Companhia Sulamericana de Distribuição</strong>
      </p>
      <p>
        Surge uma nova empresa. Em 12 /01 / 2010 surge a CSD, empresa muito
        jovem advinda da fusão entre os Supermercados São Francisco e os
        Supermercados Cidade Canção.A amizade e a visão empresarial dos
        fundadores das redes, Carlos Alberto Tavares e Jefferson Nogarolli,
        transformou a concorrência em aliança.A busca pelo crescimento acelerado
        gerou interesse de investidores externos, após pouco tempo de
        negociações, mais precisamente em 02 /09 / 2010 o fundo Actis adquiriu
        33 % das ações da Companhia. A Companhia possui hoje 46 lojas
        distribuídas nos estados de São Paulo, Paraná e Mato Grosso do Sul, e
        permanece em constante expansão.A rede conta hoje com um total de 46.518
        m² de área de vendas e opera em multiformatos, Cidade Canção, São
        Francisco e Cooper Rede.
      </p>
      <p>
        Esta nova aliança estratégica possibilitou a competição e a expansão do
        nosso negócio com outro nível de escala e de profundo conhecimento do
        mercado regional.
      </p>
    </div>
  )
}
